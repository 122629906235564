<template>
    <div>
    <v-img
              class=" align-center "
              height="600px"
              src="../../assets/Image-2.png"
            >
        <v-row class="text-center">
          <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="bg">
            <h1 style="color:white">{{$t('home.techno.GETINTOUCHWITHUS')}}</h1>
            <p class="white--text py-2">
              {{$t('home.techno.Pleaseclickbelow')}}
            </p>
          <v-btn color="primary" outlined to="/contact">
           <h5 class="white--text">{{ $t("home.techno.ContactUs") }}</h5>
          </v-btn>
          </v-col>
        
        </v-row>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "Packages",
  computed: {
    
  },
  data() {
    return {
     
    };
  }
};
</script>

<style lang="scss" scoped>

</style>
